<template>
	<div>
		<WwModal
			:modal-id="SMS_MODAL"
			max-width="max-w-lg"
			hide-footer
			@close-prevented="handleShowError"
		>
			<template #close>
				<div class="-mt-2" />
				<transition
					v-if="showErrorMessage"
					name="slide-fade"
					class="mx-auto text-center"
				>
					<span class="absolute top-0 left-0 w-full text-center text-white bg-red-500 opacity-90 force-full-width">
						{{ inputRequiredMessage }}
					</span>
				</transition>
			</template>
			<template #body>
				<div class="text-center">
					<SMSEnterNumber
						v-if="currentStepIndex === 0"
						v-model="phoneNumber"
						:focus-on-input="focusOnInput"
						@enter-number-data="setPhoneNumber"
						@focus-to-false="focusToFalse"
						@final-step="finalStep"
					/>

					<SMSEnterCode
						v-else-if="currentStepIndex === 1"
						v-model="verificationCode"
						:phone-number="phoneNumber"
						:method="method"
						:phone-number-array="phoneNumberArray"
						:focus-on-input="focusOnInput"
						@reset-number="resetNumber"
						@final-step="finalStep"
						@focus-to-false="focusToFalse"
					/>
				</div>
				<div
					v-if="progress == 3"
					class="text-center"
				>
					<h3 class="mt-4 mb-4">
						Success!
					</h3>
					<p>
						Phone number registered.
					</p>
				</div>
			</template>
		</Wwmodal>
	</div>
</template>

<script async>
import { mapActions, mapMutations, mapState } from 'vuex'

import WwModal from '@/components/UI/WwModal.vue'
import { SMS_MODAL } from '@/constants/modal/names.js'

export default {
	components: {
		SMSEnterNumber: () => import('@/components/multiUse/SMSEnterNumber.vue'),
		SMSEnterCode: () => import('@/components/multiUse/SMSEnterCode.vue'),
		WwModal
	},
	emits: [ 'success' ],
	data() {
		return {
			SMS_MODAL,
			smsSteps: [ 'EnterPhoneNumber', 'EnterVerificationCode' ],
			currentStepIndex: 0,
			returnToStep: 0,
			max: 100,
			phoneNumber: '',
			phoneNumberArray: [],
			method: '',
			verificationCode: '',
			focusOnInput: false,
			inputRequiredMessage: '',
			showErrorMessage: false
		}
	},
	computed: {
		...mapState('modal', [
			'activeModalId',
			'closePrevented'
		]),
		progress() {
			return this.currentStepIndex + 1
		},
		isFinalStep() {
			return this.progress === 3
		}
	},
	watch: {
		activeModalId: {
			handler() {
				if (this.activeModalId === SMS_MODAL) this.preventClose()
			},
			immediate: true
		},
		progress: {
			handler() {
				if (this.isFinalStep) this.allowClose()
			},
			immediate: true
		}
	},
	methods: {
		...mapMutations('modal', [
			'preventClose',
			'allowClose',
			'closeModal'
		]),
		...mapActions('auth', [ 'setVerified' ]),
		focusToFalse() {
			this.focusOnInput = false
		},
		goBack() {
			this.currentStepIndex--
		},
		goForward() {
			this.currentStepIndex++
		},
		handleShowError() {
			this.showErrorMessage = true
			this.focusOnInput = true
			this.inputRequiredMessage = 'Input Required - Complete Phone Verification'
			setTimeout(() => { this.showErrorMessage = false }, 2000)
		},
		finalStep(step) {
			this.setVerified()
			this.currentStepIndex = step
			this.$nextTick(() => {
				this.$emit('success')
			})
			this.allowClose()
			setTimeout(() => { this.closeModal() }, 1000)
		},
		setPhoneNumber(data) {
			this.phoneNumber = data.phone
			this.method = data.method
			this.phoneNumberArray = data.phoneNumberArray
			this.currentStepIndex = data.step
		},
		resetNumber() {
			this.currentStepIndex = 0
			this.progress = 1
			this.phoneNumber = ''
			this.phoneNumberArray = []
			this.returnToStep = 0
		}
	}
}
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.force-full-width {
  width: 100vw;
	margin-left: calc(-50vw + 50%);
	margin-right: calc(50vw - 50%);
}
.sms-modal {
	p {
		font-size: 0.85em;
	}
	.phone-input {
		padding-bottom: 10px;

		input {
			text-align: center;
			border: 0;
			border-bottom: 1px solid #ccc;
			width: 18px;
			margin: 0;
			font-size: 1.5em;
			color: $gray1;
			padding: 0;
		}
		input[type=number] {
			-moz-appearance: textfield;
		}
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		.split {
			color: $gray3;
			line-height: 0.5em;
			position: relative;
		}
		&.code {
			font-size: 38px;
			input {
				width: 40px;
			}
		}
	}
	.verify-type {
		min-width: 300px;
	}
}
</style>
