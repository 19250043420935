<template>
	<div class="relative w-12 h-12 overflow-hidden border border-gray-300 rounded-full shadow-sm">
		<img
			:src="businessImage"
			alt="Where's Weed user"
			:width="width"
			:height="height"
			:class="[ 'w-full', { 'p-2': !hasImage }]"
			@error="setFallbackImage"
		>
	</div>
</template>

<script async>
import { LISTING_IMAGES } from '@/constants/images/image-folder-paths.js'
import buildImageUrl from '@/utils/builders/buildImageUrl.js'

export default {
	props: {
		width: {
			type: Number,
			default: 50
		},
		height: {
			type: Number,
			default: 50
		},
		image: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			hasImage: true
		}
	},
	computed: {
		fallbackImage() {
			return require('@/assets/icons/shop.svg')
		},
		businessImage() {
			if (!this.image) return this.fallbackImage
			const imageOptions = {
				imagePath: LISTING_IMAGES, width: 60, height: 60, file: this.image
			}
			return buildImageUrl(imageOptions)
		}
	},
	mounted() {
		if (!this.image) {
			this.hasImage = false
		}
	},
	methods: {
		setFallbackImage(event) {
			event.target.src = this.fallbackImage
			this.hasImage = false
		}
	}
}
</script>
